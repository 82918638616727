




















































.p-index {
  height: 100%;
  display: flex;
  flex-direction: column;
  a {
    &:hover {
      color: #41ac76;
    }
  }
  .icon-splitter {
    font-size: 14px;
    color: #000000;
  }
  > .head {
    background-color: #ffffff;
    > .inner {
      width: 1100px;
      height: 80px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      > .left {
        display: flex;
        align-items: center;
        > .title {
          font-size: 24px;
          margin-left: 20px;
        }
      }
      > .right {
        > a {
          &:not(:hover) {
            color: #999999;
          }
          > i {
            font-size: 14px;
          }
        }
      }
    }
  }
  > .foot {
    font-size: 12px;
    color: #666666;
    line-height: 36px;
    padding: 50px 0px 0px 0px;
    background-color: #ffffff;
    > .inner {
      width: 1100px;
      margin: 0 auto;
      > .top {
        border-bottom: 1px solid #cccccc;
        > .hotline {
          margin-right: 30px;
        }
      }
      > .bottom {
        > .solution {
          margin: 0 30px 0 205px;
        }
        > .copyright {
          color: #999999;
          margin-left: 30px;
        }
      }
    }
  }
}
