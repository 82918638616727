.p-index {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.p-index a:hover {
  color: #41ac76;
}
.p-index .icon-splitter {
  font-size: 14px;
  color: #000000;
}
.p-index > .head {
  background-color: #ffffff;
}
.p-index > .head > .inner {
  width: 1100px;
  height: 80px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}
.p-index > .head > .inner > .left {
  display: flex;
  align-items: center;
}
.p-index > .head > .inner > .left > .title {
  font-size: 24px;
  margin-left: 20px;
}
.p-index > .head > .inner > .right > a:not(:hover) {
  color: #999999;
}
.p-index > .head > .inner > .right > a > i {
  font-size: 14px;
}
.p-index > .foot {
  font-size: 12px;
  color: #666666;
  line-height: 36px;
  padding: 50px 0px 0px 0px;
  background-color: #ffffff;
}
.p-index > .foot > .inner {
  width: 1100px;
  margin: 0 auto;
}
.p-index > .foot > .inner > .top {
  border-bottom: 1px solid #cccccc;
}
.p-index > .foot > .inner > .top > .hotline {
  margin-right: 30px;
}
.p-index > .foot > .inner > .bottom > .solution {
  margin: 0 30px 0 205px;
}
.p-index > .foot > .inner > .bottom > .copyright {
  color: #999999;
  margin-left: 30px;
}
